.wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  }
  
.logo {
object-fit: cover;
width: 60px;
border-radius: 50%;
margin-right: 10px;
}

.logo-wrapper {
display: flex;
align-items: center;
justify-content: flex-start;
}

.name {
font-size: 18px;
font-weight: bold
}

.tg-id {
    font-size: 14px;
}

.status {
display: flex;
align-items: center;
gap: 4px;
font-size: 14px;
}

.stats {
    font-size: 14px;
    color: black;
    margin-top: 10px;
}

.section {
    margin-top: 20px;
    padding: 10px 14px;
    border-radius: 10px;
}

.key-button {
border: none;
background: none;
margin: 0;
padding: 0;
text-align: right;
font-size: 14px;
}

.custom-action-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: rgb(250, 10, 25);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  h2 {
    font-size: 18px;
  }

  .referral-description {
    font-size: 14px;
  }

  .status-bar-container {
    width: 100%;
    background-color: #eee;
    border-radius: 10px;
    height: 20px;
    margin-top: 10px;
  }
  
  .status-bar {
    height: 100%;
    transition: width 0.5s ease, background-color 0.5s ease;
  }

  .horizontal-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }

  .button-group.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .button-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }